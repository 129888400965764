<template>
  <div class="">
    <div class="container mx-auto">
      <div class="">
        <Breadcrumb />
      </div>
      <div class="lg:flex lg:justify-between mb-8" v-if="data_obtained">
        <div>
          <Intro :msg="event.data.title" />
        </div>
        <div class="px-4">
          <div class="mb-2">
            <div>
              <p class="inline-block text-lg">
                {{ event.data.day }} {{ event.data.date }}
              </p>
              <span class="inline-block text-lg mx-2">-</span>
              <p class="inline-block text-lg">
                {{ event.data.time.hour }}:{{ event.data.time.minute }}
              </p>
            </div>
            <div>
              <p class="text-lg">{{ event.data.location }}</p>
              <p class="text-lg">{{ event.data.city }}</p>
            </div>
            <div>
              <p class="text-lg">
                {{ event.data.occupied_seats }} / {{ event.data.seats }} posti
                prenotati
              </p>
            </div>
            <div>
              <p v-if="event.data.video_conference" class="text-lg">
                Sala video collegata
              </p>
            </div>
          </div>
          <div class="flex justify-end mb-4">
            <button
              class="
                inline-block
                bg-white
                text-md text-red
                uppercase
                border border-red
                px-4
                py-1
                hover:text-white hover:bg-red
                cursor-pointer
                my-4
                transition-colors
                duration-500
                ease-in-out
              "
              @click="linkToUpdate(event.id)"
            >
              Modifica evento
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- <ReadAll :users="users" /> -->
    <!-- <div class="border">
      {{ users }}
    </div> -->
    <div class="container px-4 mx-auto overflow-x-auto">
      <table class="w-full">
        <tr class="border-b">
          <td class="text-lg text-red font-bold uppercase py-2">Check in</td>
          <td class="text-lg text-red py-2">Cognome</td>
          <td class="text-lg text-red py-2">Nome</td>
          <td class="text-lg text-red py-2">Carta</td>
          <td class="text-lg text-red py-2">Email</td>
          <td class="text-lg text-red py-2">Telefono</td>
          <td class="text-lg text-red py-2">Età</td>
        </tr>
        <tr
          class="border-b hover:bg-grey-light"
          v-for="(user, index) in users"
          :key="index"
          v-bind:class="{ 'opacity-40': user.checked == true }"
        >
          <td class="px-6">
            <div class="flex flex-col justify-center">
              <!-- <input
                type="checkbox"
                class="w-5 h-5 cursor-pointer"
                id="checked"
                name="checked"
                v-model="user.checked"
                @click="checkIn(user.id, !user.checked)"
              /> -->
              <md-checkbox
                class=""
                id="checked"
                name="checked"
                v-model="user.checked"
                @change="checkIn(user.id, user.checked)"
              >
              </md-checkbox>
            </div>
          </td>
          <td class="py-2">
            <p>{{ user.surname }}</p>
          </td>
          <td class="py-2">
            <p>{{ user.name }}</p>
          </td>
          <td class="py-2">
            <p>{{ user.card }}</p>
          </td>
          <td class="py-2">
            <p>{{ user.email }}</p>
          </td>
          <td class="py-2">
            <p>{{ user.phone }}</p>
          </td>
          <td class="py-2">
            <p>{{ user.age }}</p>
          </td>
          <!-- <td> -->
          <button
            class="
              inline-block
              bg-white
              text-md text-red
              uppercase
              border border-red
              px-1
              py-1
              hover:text-white hover:bg-red
              cursor-pointer
              my-4
              transition-colors
              duration-500
              ease-in-out
            "
            @click="deletePartecipant(user.id, user.email)"
          >
            <svg
              class="h-4 w-4 text-red-500"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <polyline points="3 6 5 6 21 6" />
              <path
                d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"
              />
              <line x1="10" y1="11" x2="10" y2="17" />
              <line x1="14" y1="11" x2="14" y2="17" />
            </svg>
          </button>
          <!-- </td> -->
        </tr>
        <!-- New row -->
        <tr v-if="new_row" class="">
          <td class="px-6">
            <md-checkbox
              class=""
              id="check"
              name="check"
              v-model="new_user.checked"
            >
            </md-checkbox>
          </td>
          <td class="pr-8 py-2">
            <input
              type="text"
              class="w-full border-b focus:outline-none"
              id="name"
              name="name"
              v-model="new_user.surname"
              required
            />
          </td>
          <td class="pr-8 py-2">
            <input
              type="text"
              class="w-full border-b focus:outline-none"
              id="name"
              name="name"
              v-model="new_user.name"
              required
            />
          </td>
          <td class="pr-8 py-2">
            <select
              class="w-full border focus:outline-none"
              id="cards"
              name="cards"
              v-model="new_user.card"
              required
            >
              <option
                v-for="card in cards"
                v-bind:value="card.value"
                :key="card.value"
              >
                {{ card.text }}
              </option>
            </select>
          </td>
          <td class="pr-8 py-2">
            <!-- <input
              type="email"
              class="w-full border-b focus:outline-none"
              id="email"
              name="email"
              v-model="new_user.email"
              required
            /> -->
            <!-- Email -->
            <div class="md-layout-item md-small-size-100">
              <md-field :class="getValidationClass('email')">
                <!-- <label class="block text-red mb-1" for="email">Email*</label> -->
                <md-input
                  class="focus:outline-none"
                  type="email"
                  id="email"
                  name="email"
                  v-model="new_user.email"
                />
                <span class="md-error" v-if="!$v.new_user.email.required"
                  >Questo campo è obbligatorio</span
                >
                <span class="md-error" v-else-if="!$v.new_user.email.email"
                  >Questo campo non è valido</span
                >
              </md-field>
            </div>
          </td>
          <td class="pr-8 py-2">
            <input
              type="text"
              class="w-full border-b focus:outline-none"
              id="phone"
              name="phone"
              pattern="[0-9]{10}"
              v-model="new_user.phone"
              required
            />
          </td>
          <td class="py-2">
            <select
              class="w-full border focus:outline-none"
              id="age"
              name="age"
              v-model="new_user.age"
              required
            >
              <option v-for="a in age" v-bind:value="a.value" :key="a.value">
                {{ a.text }}
              </option>
            </select>
          </td>
        </tr>
      </table>
      <div class="container mx-auto">
        <button
          v-if="!new_row"
          class="
            inline-block
            bg-white
            text-md text-red
            uppercase
            border border-red
            px-4
            py-1
            hover:text-white hover:bg-red
            cursor-pointer
            my-4
            transition-colors
            duration-500
            ease-in-out
          "
          :class="{
            'opacity-50 cursor-not-allowed':
              event.data.occupied_seats >= event.data.seats,
          }"
          v-on:click="
            event.data.occupied_seats < event.data.seats ? addRowToTable() : ''
          "
        >
          Aggiungi partecipante
        </button>
        <button
          v-else
          class="
            inline-block
            bg-white
            text-md text-red
            uppercase
            border border-red
            px-4
            py-1
            hover:text-white hover:bg-red
            cursor-pointer
            my-4
            transition-colors
            duration-500
            ease-in-out
          "
          @click="validateNewUser()"
        >
          <!-- @click="addNewUser()" -->
          Salva
        </button>
      </div>
      <!-- <div>
        {{ new_user }}
      </div> -->
    </div>
    <!-- Download excel -->
    <div class="container px-4 mx-auto">
      <div class="flex lg:justify-end px-4 my-4">
        <a class="cursor-pointer">
          <svg
            class="inline-block h-4 w-4 text-black mr-1 mb-1"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke-width="2"
            stroke="currentColor"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" />
            <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2" />
            <polyline points="7 11 12 16 17 11" />
            <line x1="12" y1="4" x2="12" y2="16" />
          </svg>
          <download-excel
            class="inline-block text-body"
            :data="users"
            :fields="users_fields"
            name="users.xls"
            >Scarica in xls</download-excel
          >
        </a>
      </div>
    </div>
    <!-- Delete event -->
    <div class="container px-4 mx-auto">
      <div class="flex justify-end mb-4">
        <button
          class="
            inline-block
            bg-white
            text-md text-red
            uppercase
            border border-red
            px-4
            py-1
            hover:text-white hover:bg-red
            cursor-pointer
            my-4
            transition-colors
            duration-500
            ease-in-out
          "
          @click="deleteEvent()"
        >
          Elimina evento
        </button>
      </div>
    </div>
    <!-- <Modal :visible="this.modal" @visible="modalStatus" /> -->
  </div>
</template>


<script>
// Firebase
import firebase from "@/firebase";
import firebaseApp from "firebase/app";
import UsersDataService from "@/services/UsersDataService";
import EventsDataService from "@/services/EventsDataService";
// Components
import Intro from "@/components/component/Intro.vue";
import Breadcrumb from "@/components/component/Breadcrumb.vue";
// import Modal from "@/components/component/Modal.vue";
// import ReadAll from "@/components/reservations/Read.vue";
// import DownloadExcel from "@/components/component/DownloadExcel.vue";

// Vuelidate
import { validationMixin } from "vuelidate";
import {
  required,
  email,
  minLength,
  maxLength,
} from "vuelidate/lib/validators";

export default {
  name: "Reservations",
  mixins: [validationMixin],
  components: {
    Intro,
    Breadcrumb,
    // Modal,
    // ReadAll,
    // DownloadExcel,
  },
  data() {
    return {
      event: {
        id: null,
        data: [],
      },
      user: {
        name: "",
        surname: "",
        email: "",
        phone: "",
      },
      users_id: [],
      users: [],
      users_fields: {
        checked: "checked",
        name: "name",
        surname: "surname",
        email: "email",
        phone: "phone",
        age: "age",
        card: "card",
        message: "message",
        incipit: "incipit",
        newsletter_torino: "newsletter.torino",
        newsletter_novara: "newsletter.novara",
        newsletter_verbania: "newsletter.verbania",
        // conditions: "conditions",
      },
      cards: [
        {
          text: "Non posseggo carte del Circolo",
          value: "Non posseggo carte del Circolo",
        },
        { text: "Carta Io leggo", value: "Carta Io leggo" },
        { text: "Carta Io leggo di Più", value: "Carta Io leggo di Più" },
      ],
      age: [
        { text: "Under 18", value: "Under 18" },
        { text: "18-24 anni", value: "18-24 anni" },
        { text: "25-29 anni", value: "25-29 anni" },
        { text: "30-39 anni", value: "30-39 anni" },
        { text: "40-49 anni", value: "40-49 anni" },
        { text: "50-59 anni", value: "50-59 anni" },
        { text: "60-69 anni", value: "60-69 anni" },
        { text: "Over 70", value: "Over 70" },
      ],
      new_row: false,
      new_user: {
        checked: false,
        surname: null,
        name: null,
        email: null,
        phone: null,
        age: null,
      },
      event_occupied_seats: null,
      modal: false,
      data_obtained: false,
    };
  },
  validations: {
    new_user: {
      email: {
        required,
        email,
      },
    },
  },
  mounted() {
    this.getInfoSingleEvent();
  },
  methods: {
    async getInfoSingleEvent() {
      let docID = this.$route.params.id;
      // console.log("docID: ", docID);
      this.event.id = docID;

      EventsDataService.read(docID)
        .then((doc) => {
          if (doc.exists) {
            // console.log("Document data:", doc.data());
            // console.log("Users:", doc.data().users);
            this.event.data = doc.data();
            // this.users_id = doc.data().users;
            let _users = doc.data().users;
            _users.forEach((user) => {
              let array_id = this.users_id;
              array_id.push(user.id);
            });
            // console.log("this.users_id: ", this.users_id);
          } else {
            // doc.data() will be undefined in this case
            // console.log("No such document!");
          }
        })
        .then(() => {
          this.getInfoBookedUser();
          this.data_obtained = true;
        })
        .catch((error) => {
          console.log("Error: ", error);
        });
    },

    getInfoBookedUser() {
      let _users_id = this.users_id;
      _users_id.forEach((id) => {
        // console.log("Id: ", id);

        UsersDataService.read(id)
          .then((doc) => {
            // console.log("Doc: ", doc.data());
            let user_info = doc.data();
            user_info.id = id;
            this.users.push(user_info);
          })
          .then(() => {
            let _users = this.users;
            _users.sort(this.compareObjectsValue);
          })
          .catch((error) => {
            console.log("Error: ", error);
          });
      });
    },

    compareObjectsValue(a, b) {
      if (a.surname < b.surname) {
        return -1;
      }
      if (a.surname > b.surname) {
        return 1;
      }
      return 0;
    },

    checkIn(user_id, user_checked) {
      console.log(
        "checkIn - user_id: " + user_id + "user_checked: " + user_checked
      );
      let check_in = {
        checked: user_checked,
      };
      UsersDataService.update(user_id, check_in)
        .then(() => {
          console.log("Update");
        })
        .catch((error) => {
          console.log("Error: ", error);
        });
    },
    linkToUpdate(eventID) {
      this.$router.push({ name: "UpdateEvent", params: { id: eventID } });
    },
    addRowToTable() {
      this.new_row = true;
    },

    // Verifico che il singolo campo sia valido
    getValidationClass(fieldName) {
      const field = this.$v.new_user[fieldName];

      if (field) {
        return {
          "md-invalid": field.$invalid && field.$dirty,
        };
      }
    },

    // Verifico che tutti i campi del form siano validi
    validateNewUser() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.addNewUser();
      }
    },

    addNewUser() {
      let new_user = {
        ...this.new_user,
        events: [this.event.id],
        createdAt: new Date(),
      };

      UsersDataService.create(new_user)
        .then((docRef) => {
          // console.log("Created new item successfully!");

          const householdRef = firebase
            .collection("/events")
            .doc(this.event.id);

          let newUid = docRef.id;
          const user_email = new_user.email;

          return firebase
            .runTransaction((t) => {
              return t.get(householdRef).then((doc) => {
                // console.log("DOC: ", doc);

                // doc doesn't exist; can't update
                if (!doc.exists) return;

                // update the users array after getting it from Firestore.
                let newUserArray = doc.get("users");
                // console.log("newUserArray 1: ", newUserArray);
                newUserArray.push({ id: newUid, email: user_email });
                // console.log("newUserArray 2: ", newUserArray);
                this.event_occupied_seats = newUserArray.length;
                t.set(householdRef, { users: newUserArray }, { merge: true });
              });
            })
            .catch((error) => {
              console.log("Error: ", error);
            });
        })
        .then(() => {
          // EventsDataService.increaseSeats(
          //   this.event.id,
          //   this.event.data.occupied_seats
          // )
          //   .then(() => {
          //     console.log("Increase seats");
          //   })
          //   .catch((error) => {
          //     console.log("Error: ", error);
          //   });
          EventsDataService.setSeats(this.event.id, this.event_occupied_seats)
            .then(() => {
              console.log("Set seats");
            })
            .catch((error) => {
              console.log("Error: ", error);
            });
        })
        .then(() => {
          // this.users = [];
          this.new_row = false;
          this.$router.go();
        })
        .catch((error) => {
          console.log("Error: ", error);
        });
    },
    deleteEvent() {
      console.log("Delete function");
      // this.modalStatus(true);

      let confirm_deletion = confirm(
        "Confermi di voler eliminare l'evento? Attenzione: prima di procedere, scarica il file excel dei partecipanti, perchè tutti i dati dell'evento presenti sul database verranno cancellati."
      );
      if (confirm_deletion) {
        // console.log("this.event.id: ", this.event.id);
        EventsDataService.delete(this.event.id)
          .then(() => {
            console.log("Deleted event");
            this.$router.push({ name: "ReadEvents" });
          })
          .catch((error) => {
            console.log("Error: ", error);
          });
      }
    },
    deletePartecipant(user_id, user_email) {
      console.log(
        "Delete partecipant with id: " + user_id + "end email: " + user_email
      );

      let confirm_deletion = confirm(
        "Confermi di voler eliminare l'utente da questo evento?"
      );
      if (confirm_deletion) {
        // EventsDataService.deletePartecipantInTheEvent(this.event.id, user_id)
        firebase
          .collection("/events")
          .doc(this.event.id)
          .update({
            users: firebaseApp.firestore.FieldValue.arrayRemove({
              email: user_email,
              id: user_id,
            }),
          })
          .then(() => {
            console.log("Deleted partecipant");

            // Decremento di uno il numero di posti prenotati
            EventsDataService.read(this.event.id)
              .then((doc) => {
                console.log("Document data:", doc.data());
                let _occupied_seats = doc.data().occupied_seats;
                // alert(_occupied_seats);

                EventsDataService.decreaseSeats(this.event.id, _occupied_seats)
                  .then(() => {
                    console.log("Decrease seats");
                  })
                  .catch((error) => {
                    console.log("Error: ", error);
                  });
              })
              .then(() => {
                this.$router.go();
              })
              .catch((error) => {
                console.log("Error: ", error);
              });
          })
          .catch((error) => {
            console.log("Error: ", error);
          });
      }
    },
    modalStatus(value) {
      // console.log("modal status: ", value);
      this.modal = value;
    },
  },
};
</script>

<style scoped lang="scss">
</style>